document.addEventListener('DOMContentLoaded', () => {

  const menuSwitch = document.querySelector('#burger_menu_switch');

  menuSwitch.addEventListener('change', (event) => {
    if (menuSwitch.checked) {
      document.addEventListener('click', (clickEvent) => {
        if (!clickEvent.target.closest('.header_wrapper')) {
          menuSwitch.checked = false;
        }
      });
    }
  });

});