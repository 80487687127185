import 'vanilla-cookieconsent/dist/cookieconsent.js';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

window.addEventListener('load', function () {
  // obtain cookieconsent plugin
  var cookieconsent = initCookieConsent();

  const loadGtag = function (cc) {
    cc.loadScript('https://www.googletagmanager.com/gtag/js?id=UA-26177183-1', function () {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-26177183-1');

      gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    });
  };

  // run plugin with config object
  cookieconsent.run({
    autorun: true,
    current_lang: 'fr',
    autoclear_cookies: true,
    page_scripts: true,

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },

    onAccept: function (cookie) {
      // ... cookieconsent accepted
      if (cookieconsent.allowedCategory('analytics')) {
        loadGtag(cookieconsent);
      }
    },

    onChange: function (cookie, changed_preferences) {
      // ... cookieconsent preferences were changed
      if (!cookieconsent.allowedCategory('analytics')) {
        typeof gtag === 'function' && gtag('consent', 'update', {
          'analytics_storage': 'denied'
        });
      } else {
        loadGtag(cookieconsent);
      }
    },

    languages: {
      fr: {
        consent_modal: {
          title: 'Nous utilisons des cookies',
          description: 'Bonjour, ce site utilise Google Analytics pour mesurer l\'audience. Google Analytics a besoin de stocker un cookie dans votre navigateur.',
          primary_btn: {
            text: 'Accepter',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Paramètres',
            role: 'settings'                // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Préférences des cookies',
          save_settings_btn: 'Sauvegarder',
          accept_all_btn: 'Tout accepter',
          reject_all_btn: 'Tout rejeter',       // optional, [v.2.5.0 +]
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' },
            { col5: 'Type' }
          ],
          blocks: [
            {
              title: 'Utilisation des cookies',
              description: 'Le site Synergie.Aero utilise Google Analytics pour mesurer l\'audience du site et ainsi proposer la meilleure expérience possible. Google Analytics a besoin de stocker des cookies pour fonctionner.'
            }, {
              title: 'Cookies analytics',
              description: 'Ces cookies collectent des informations sur votre utilisation du site Web, quelles pages vous avez visité, sur quels liens vous avez cliqué.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'description ...',
                  col5: 'Permanent cookie',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 day',
                  col4: 'description ...',
                  col5: 'Permanent cookie'
                }
              ]
            }
          ]
        }
      }
    }
  });
});