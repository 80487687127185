import {
  Splide,
  SplideSlide
} from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import './cookieconsent.js';
import './intersectionObserver.js';
import './burgermenu.js';

let slider = new Splide('.splide', {
  type: 'loop',
  pagination: 'true',
  autoplay: 'true',
  interval: '4000',
  speed: '1000',
  breakpoints: {
		640: {
      autoplay: 'pause',
			// classes: { arrows: 'hidden_on_mobile' }
		},
  }
});

slider.mount();

window.addEventListener('load', () => {
  const header = document.querySelector('header.main_header');
  const mainArticle = document.querySelector('article.hero_header');

  mainArticle.style.height = window.innerHeight - header.clientHeight + 'px';
});

// Set the name of the hidden property and the change event for visibility
let hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

// Pauses background video player and Splide carousel on focus loss
document.addEventListener(visibilityChange, () => {
  if (document.hidden) {
    document.querySelector('.background_video').pause();
    slider.Components.Autoplay.pause();
  } else {
    document.querySelector('.background_video').play();
    slider.Components.Autoplay.play();
  }
}, false);
