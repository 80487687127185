const triggerAnimation = function (countAnimation) {
  let start = 0;
  let end = countAnimation.dataset.target;
  let duration = 2000;
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    countAnimation.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

let observedItems = document.querySelectorAll('.observed_item, .brand_item');

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('brand_item')) {
        entry.target.classList.add('fade_in_up');
      } else {
        entry.target.classList.add('is_scrolled');
        entry.target.querySelectorAll('.count_animation')
        .forEach(
          key => triggerAnimation(key)
          );
        }
      observer.unobserve(entry.target);
    }
  });
}, { threshold: .5 });

window.addEventListener('load', (event) => {
  observedItems.forEach(item => {
    item.querySelectorAll('.count_animation').forEach(span => {
      span.style.minWidth = span.offsetWidth + 'px';
    });

    observer.observe(item);
  });
});